<template>
    <div class="online">
        <my-banner :bg_banner="require('@/assets/img/online_banner.jpg')"></my-banner>
        <div class="w1200">
            <div class="code">
                <div class="p1" v-html="$options.filters.sLang('开立账户，从未如此简单')">
                    
                </div>
                <div class="p2" v-html="$options.filters.sLang('通过恒天国际线上应用程序，轻松完成开户。申请过程仅需15分钟，方便快捷！')">
                    
                </div>
                <div class="p3">
                    <img :src="require('@/assets/img/online_code.png')" alt/>
                </div>
                <div class="p4">
                    {{$t('online.code')}}{{$t('online.forThe')}}
                    <!-- <span>/</span><a  target="_blank" href="https://htgca.iqdii.com/view/accountopening/htg/#/?lang=chs&org=htg&channel=30&custno=&openaccttype=0&typeno=&fromcomp=jyb&currentClient=0">
                    {{$t('online.clickThe')}}</a>-->
                </div>
            </div>
            <div class="steps">
                <div class="p1" v-html="$options.filters.sLang('操作步骤')">
                    
                </div>
                <div class="stepsflex">
                    <el-steps :active="6" align-center>
                        <el-step  :description="$t('online.one')"></el-step>
                        <el-step class="zhe" :description="$t('online.two')"></el-step>
                        <el-step  :description="$t('online.three')"></el-step>
                        <el-step  :description="$t('online.four')"></el-step>
                        <el-step  :description="$t('online.five')"></el-step>
                        <el-step  :description="$t('online.six')"></el-step>
                    </el-steps>
                </div>
            </div>
            <div class="attention">
                <div class="p1" v-html="$options.filters.sLang('注意事项')">
                    
                </div>
                <div class="p2" v-html="$options.filters.sLang('1. 使用线上应用程序开立账户，仅适用于个人投资者；')">
                    
                </div>
                <div class="p2" v-html="$options.filters.sLang('2. 当阁下成功提交开户申请后，恒天国际预计在3个工作日内与阁下取得联系并沟通开户内容。当账户成功开立，阁下将收到电邮通知；')">
                    
                </div>
                <div class="p2" v-html="$options.filters.sLang('3. 如有任何疑问，欢迎来电查询。服务热线：(852) 3702 7888。')">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import myBanner from '@/components/myBanner/index.vue'
export default {
    name: 'online',
    components: {
        myBanner: myBanner,
    },
    data() {
    return {
      
    }
  },
}
</script>

<style scoped lang='scss'>
.online{
    .w1200{
        // overflow: hidden;
        .code{
            margin-top: 22px;
            background: #FFFFFF;
            box-shadow: 0px 4px 12px 0px rgba(129, 129, 129, 0.3);
            border-radius: 8px;
            padding-top: 53px;
            padding-bottom: 36px;
            margin-bottom: 20px;
            .p1{
                height: 20px;
                font-size: 20px;
                font-weight: bold;
                color: #272727;
                text-align: center;
                margin-bottom:11px;
            }
            .p2{
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                text-align: center;
                margin-bottom:32px;
            }
            .p3{
                width: 277px;
                height: 277px;
                margin: 0 auto 30px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .p4{
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #272727;
                text-align: center;
                span{
                    margin-left: 5px;
                }
                a{
                    color: #0C58F5;
                }
            }
        }
        .steps{
            background: #FFFFFF;
            box-shadow: 0px 4px 12px 0px rgba(129, 129, 129, 0.3);
            border-radius: 8px;
            padding-top: 29px;
            padding-bottom: 42px;
            margin-bottom: 20px;
            .p1{
                height: 17px;
                font-size: 18px;
                font-weight: bold;
                color: #272727;
                text-align: center;
                margin-bottom: 39px;
            }
            .stepsflex{
                margin-left: 45px;
                margin-right: 45px;
                ::v-deep.el-step__description{
                    font-size: 14px;
                    font-weight: 400;
                    color: #272727;
                    line-height: 22px;
                    margin-top: 17px;  
                }
                .zhe ::v-deep.el-step__description{
                    padding-left:17%;
                    padding-right:17%;
                }
                ::v-deep.el-step__icon{
                    width: 44px;
                    height: 44px;
                    background: #dab678;
                    border-radius: 50%;
                    border: 0;
                }
                ::v-deep.el-step__icon-inner{
                    color: #272727;
                    font-size: 20px;
                }
                ::v-deep.el-step__line{
                    background: transparent;
                    border-top: 1px dotted;
                    color: #787878;
                    top:22px;
                    .el-step__line-inner{
                    border-width: 0 !important;
                    }
                }
                
                
            }
        }
        .attention{
            background: #FFFFFF;
            box-shadow: 0px 4px 12px 0px rgba(129, 129, 129, 0.3);
            border-radius: 8px;
            padding-top: 30px;
            padding-bottom: 34px;
            margin-bottom: 40px;
            .p1{
                height: 17px;
                font-size: 18px;
                font-weight: bold;
                color: #272727;
                text-align: center;
                margin-bottom: 32px;
            }
            .p2{
                font-size: 14px;
                font-weight: 400;
                color: #272727;
                line-height: 28px;
                margin-left: 74px;
            }
        }
    }
}
</style>